/**
 * Subscription form handling
 *
 * This from Magic's dev team
 *
 * @since   1.0.0
 * @package wine-festival
 */
(function($) {
	$(document).ready(function() {

    // DZ: Added form var + selecting #s inside form
    var $form  = $('form#winesub'      );
    var $email = $('#email'    , $form );
    var $fname = $('#fname'    , $form );
    var $lname = $('#lname'    , $form );
    var $phone = $('#phone'    , $form );
    var $news  = $('#winenews' , $form );

    $($news).on('change', function() {
      if ($(this).is(':checked')) {
        $(this).attr('value', 'true');
      } else {
        $(this).attr('value', 'false');
      }
    });

    // DZ: using form var
    $form.submit(function(e) {

      // DZ: using form var
      $form.append('<span class="loading"><img src="https://i.cdn.turner.com/drp/nba/magic/sites/default/files/magic-loading.gif"></span>');

      e.preventDefault();

      var subscribe = {
        email_address: $email.val(),
        subscriptions: {
          wine_auction: true,
          wine_auction_cp: JSON.parse($news.val())
        },
        profile: {
          first_name: $fname.val(),
          last_name: $lname.val(),
          phone: $phone.val()
        }
      };

      var subSuccess = '<span class="success">Thanks for your interest in the Orlando Wine Festival & Auction! You are now subscribed to receive updates and information.</span>';

      $.ajax({
        contentType: 'application/json',
        type: "POST",
        url:  'https://subscriber-center.orlando-magic.ceroic.com/subscribers/',
        dataType: "json",
        data: JSON.stringify(subscribe),
        success: function(newEmail) {
          $( 'input, [type="submit"]', $form ).prop( 'disabled', true );
          $('.loading').remove();
          $form.append(subSuccess);
        },
        error: function(xhr, resp, text) {
          console.log(xhr, resp, text);
        }

      });
    });

	}); // document.ready
})(jQuery);
